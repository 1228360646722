import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { ModalContentStickyBottom } from 'common/components/Modal/styles';
import { crypto, fiat } from 'common/utils/formatting';
import {
  BottomText,
  ConfirmWrapper,
  CustomListItemBox,
  Title,
} from 'common/components/TradingRulesCommonComponents/TradingRulesCreationStyles/confirmationStyles';
import { getSelectedCryptoCurrency } from 'store/selectors/currency';
import { State } from 'store/types/store';
import AssetIcon from 'common/components/Icon/AssetIcon';
import ListItem from 'common/components/LayoutContainer/LayourNarrow/ListItem/ListItem';
import StopOrderSummaryText from 'common/components/LayoutContainer/LayourNarrow/SummaryText/StopOrderSummaryText/StopOrderSummaryText';
import { submitTradingStopRule as submitTradingStopRuleAction } from 'store/actions/stopOrders';
import { useAsset } from 'common/hooks/useAsset';
import { getCryptoPrecision } from 'store/slices/assets/selectors';
import withRouter from 'common/hoc/WithRouter';
import { WithRouterType } from 'types/withRouter';
import { formatWithLocale } from 'common/utils/date';
import { ASSET_CLASSES, Crypto } from 'types/assets';
import { IconWrapper } from './styles';

type Props = {
  stopPrice: string;
  cryptoAmount: string;
  euroAmount: string;
  validUntil: string;
  isBuy: boolean;
  currencyPrecision: number;
  cryptoPrecision: number | undefined;
};

type StateProps = {
  selectedCrypto: string;
};

const StopOrderConfirmView = ({
  selectedCrypto,
  stopPrice,
  cryptoAmount,
  euroAmount,
  validUntil,
  isBuy,
  currencyPrecision,
  cryptoPrecision,
}: Props & StateProps & WithRouterType): ReactElement => {
  const { t } = useTranslation();
  const cryptoInfo = useAsset(selectedCrypto, ASSET_CLASSES.CRYPTO, undefined) as Crypto | undefined;

  const showValidUntil = formatWithLocale(new Date(validUntil), t('l10n.dateTimeFormatShort'));
  const orderType = isBuy ? 'Buy' : 'Sell';

  const priceLimitText = fiat(stopPrice, { customPrecision: currencyPrecision });
  const cryptoAmountText = `${crypto(cryptoAmount, cryptoPrecision)} ${selectedCrypto.toUpperCase()}`;
  const euroAmountText = fiat(euroAmount);

  return (
    <ConfirmWrapper>
      <Title>
        <div>{t('stopOrders.detail.overview')}</div>
        <IconWrapper color={cryptoInfo?.color ?? ''}>
          <AssetIcon code={cryptoInfo?.code ?? ''} class={ASSET_CLASSES.CRYPTO} securityClass={undefined} />
        </IconWrapper>
      </Title>
      <CustomListItemBox>
        <ListItem name={t('stopOrders.detail.setStopPrice')} value={priceLimitText} />
        <ListItem
          name={t(`stopOrders.detail.cryptoAmount.${orderType.toLowerCase()}`)}
          value={cryptoAmountText}
        />
        <ListItem
          name={t(`stopOrders.detail.euroAmount.${orderType.toLowerCase()}`)}
          value={euroAmountText}
        />

        <ListItem name={t('stopOrders.detail.validUntil')} value={showValidUntil} />
      </CustomListItemBox>
      <StopOrderSummaryText
        price={priceLimitText}
        amount={cryptoAmountText}
        euroTotal={euroAmountText}
        date={validUntil}
        isBuy={isBuy}
      />
      <ModalContentStickyBottom>
        <BottomText>
          {isBuy ? t('stopOrders.confirmDescriptionLabelBuy') : t('stopOrders.confirmDescriptionLabelSell')}
        </BottomText>
      </ModalContentStickyBottom>
    </ConfirmWrapper>
  );
};

const mapStateToProps = (state: State, ownProps: WithRouterType) => {
  const selectedCrypto = getSelectedCryptoCurrency(state, ownProps.params);
  return {
    selectedCrypto,
    cryptoPrecision: getCryptoPrecision(state, selectedCrypto),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  submitTradingRule: bindActionCreators(submitTradingStopRuleAction, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StopOrderConfirmView));
