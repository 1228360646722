import React from 'react';
import { withTranslation } from 'react-i18next';
import { paths } from 'common/urls';
import PaperTradingBanner from 'common/components/Banners/PaperTradingBanner';
import { useSelector } from 'react-redux';
import { getIsUserInPaperTrading } from 'store/selectors/auth';
import { getPageTitle } from 'store/selectors/localSettings';
import KCCRaffleBanner from 'common/components/Banners/KCCRaffleBanner';
import { useKCCRaffleDisplay } from 'common/hooks/useKCCRaffleDisplay';
import { WithRouterType } from 'types/withRouter';
import { Location } from 'react-router-dom';
import IconButton from '../IconButton/IconButton';
import MainTabs from './MainTabs/MainTabs';
import { useDesktop } from '../../hooks/useMedia';
import { useMainPages } from '../../hooks/useMainPages';
import {
  BackButton,
  BisonImg,
  BisonLogo,
  BisonTextLogo,
  BurgerButton,
  CustomLayoutWrapper,
  CustomTopBarWrapper,
  HeaderContainer,
  Icons,
  IconWrapper,
  InboxButton,
  InboxIcon,
  InboxMobileButton,
  MobileTitle,
  SettingsIcon,
  TopBarContainer,
  UnreadMessagesIcon,
} from './styles';
import ProgressIndicator from '../ProgressIndicator';

// TODO: Uncomment when/if raffle comes back.
// import { useStockRaffleDisplay } from 'common/hooks/useStockRaffleDisplay';

type Props = {
  location: Location;
  selectedCrypto: string;
  unReadMessages: number;
  toggleMobileMenu?: (newState?: boolean) => void;
  nSteps?: number;
  currentStep?: number;
  mobileMenuOpen: boolean;
} & WithRouterType;
const TopBar = ({
  selectedCrypto,
  unReadMessages,
  toggleMobileMenu,
  nSteps,
  currentStep,
  mobileMenuOpen,
  location,
  navigate,
}: Props) => {
  const desktopLayout = useDesktop();

  // TODO: Uncomment when/if raffle comes back.
  // const { isDisplayed: isStockRaffleBannerDisplayed } = useStockRaffleDisplay();
  const { isDisplayed: isKCCRaffleBannerDisplayed } = useKCCRaffleDisplay();
  const showPaperTradingBanner = useSelector(getIsUserInPaperTrading);
  const isDemo = useSelector(getIsUserInPaperTrading);
  const title = useSelector(getPageTitle);
  const { backUrl, isShouldGoBack } = useMainPages();
  const handleBack = () => {
    if (isShouldGoBack) {
      navigate(-1);
    } else {
      navigate(backUrl);
    }
  };
  const isKycFlow = location.pathname.includes(paths.KYC);

  const unreadMessagesNotNaN = Number.isNaN(unReadMessages) ? 0 : unReadMessages;
  const limitedUnreadMessages = unreadMessagesNotNaN < 99 ? unreadMessagesNotNaN : 99;

  const desktopHeader = (
    <>
      <BisonLogo to={paths.PORTFOLIO}>
        <BisonImg />
        <BisonTextLogo />
      </BisonLogo>
      <MainTabs selectedCrypto={selectedCrypto} />
      <Icons>
        <IconWrapper isActive={location.pathname === paths.INBOX}>
          <InboxButton onClick={() => navigate(paths.INBOX)}>
            <InboxIcon name="icon-envelope" sizeValue="tiniest" />
            <UnreadMessagesIcon unReadMessages={limitedUnreadMessages}>
              {limitedUnreadMessages}
            </UnreadMessagesIcon>
          </InboxButton>
        </IconWrapper>
        <IconWrapper isActive={location.pathname === paths.PERSONAL_INFO}>
          <InboxButton onClick={() => navigate(paths.PERSONAL_INFO)}>
            <SettingsIcon name="icon-profile-icon" sizeValue="tiniest" />
          </InboxButton>
        </IconWrapper>
      </Icons>
    </>
  );

  const mobileHeader = (
    <>
      {backUrl || isShouldGoBack ? (
        <BackButton>
          <IconButton onClick={handleBack}>
            <InboxIcon name="icon-arrow" sizeValue="regular" />
          </IconButton>
        </BackButton>
      ) : (
        <BurgerButton>
          <IconButton
            onClick={() => {
              if (toggleMobileMenu) toggleMobileMenu(!mobileMenuOpen);
            }}
          >
            <InboxIcon name={mobileMenuOpen ? 'icon-crossIcon' : 'icon-hamburger'} sizeValue="regular" />
          </IconButton>
        </BurgerButton>
      )}
      <MobileTitle>{title}</MobileTitle>
      {isKycFlow ? (
        <ProgressIndicator max={nSteps} step={currentStep} />
      ) : (
        <InboxMobileButton>
          <InboxButton onClick={() => navigate(paths.INBOX)}>
            <InboxIcon name="icon-envelope" sizeValue="regular" />
            <UnreadMessagesIcon mobile unReadMessages={limitedUnreadMessages}>
              {limitedUnreadMessages}
            </UnreadMessagesIcon>
          </InboxButton>
        </InboxMobileButton>
      )}
    </>
  );

  const isBannerDisabled = mobileMenuOpen && !desktopLayout;

  return (
    <>
      <HeaderContainer>
        <TopBarContainer isBannerDisabled={isDemo && isBannerDisabled}>
          <CustomTopBarWrapper>{desktopLayout ? desktopHeader : mobileHeader}</CustomTopBarWrapper>
        </TopBarContainer>

        {showPaperTradingBanner && !isKycFlow && (
          <CustomLayoutWrapper>
            <PaperTradingBanner mobileMenuOpen={mobileMenuOpen} />
          </CustomLayoutWrapper>
        )}

        {/* // TODO: Uncomment when/if raffle comes back.
        {isStockRaffleBannerDisplayed && <StockRaffleBanner />} */}
        {isKCCRaffleBannerDisplayed && <KCCRaffleBanner />}
      </HeaderContainer>
    </>
  );
};

TopBar.defaultProps = {
  toggleMobileMenu: () => undefined,
  nSteps: 0,
  currentStep: 0,
};

export default withTranslation()(TopBar);
