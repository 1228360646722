import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeAndroidBanner, showAndroidInstallPrompt } from 'store/actions/androidBanner';

import { ModalProps } from 'types/modals';
import View from './view';

const StateContainer = (props: ModalProps) => {
  const dispatch = useDispatch();

  const close = useCallback(() => dispatch(closeAndroidBanner()), [dispatch]);
  const showInstallPrompt = useCallback(() => dispatch(showAndroidInstallPrompt()), [dispatch]);

  return <View close={close} showInstallPrompt={showInstallPrompt} onClose={props.onClose} />;
};

export default StateContainer;
