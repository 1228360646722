import React from 'react';
import View from 'common/components/Modal/TermsAndConditionsModal/view';
import { useTnCPopUp } from 'common/hooks/useTnCPopUp';
import { TermsAndConditionsType } from 'types/termsAndConditions';
import { ModalProps } from 'types/modals';

type Props = {
  type: TermsAndConditionsType;
} & ModalProps;

const TermsAndConditionsModal = ({ onClose, type }: Props) => {
  const {
    agreeToTerms,
    onCancel,
    cryptoTerms,
    stocksTerms,
    cryptoRequiresSecondCheckBox,
    stocksRequiresSecondCheckBox,
  } = useTnCPopUp(onClose);

  const terms = type === TermsAndConditionsType.CRYPTO ? cryptoTerms : stocksTerms;
  const skipCheckbox2 =
    type === TermsAndConditionsType.CRYPTO ? !cryptoRequiresSecondCheckBox : !stocksRequiresSecondCheckBox;

  return (
    <View
      type={type}
      terms={terms}
      skipCheckbox2={skipCheckbox2}
      onAccept={agreeToTerms}
      onCancel={onCancel}
    />
  );
};

export default TermsAndConditionsModal;
