import React, { ReactElement, useState } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import EnhanceWithTheme from 'common/utils/theme/EnhanceWithTheme';
import { pageURLs } from 'common/urls';
import { Container, AcceptButton, FlexContainer, Button, Link, Wrapper } from './styles';
import TransitionComponent from './TransitionComponent';
import CookieConsentValues from './helpers';

const CookiePopup = (): ReactElement => {
  const { t } = useTranslation();
  const urls = pageURLs();
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [isAdditionalChecksExpanded, setIsAdditionalChecksExpanded] = useState(false);
  const [isMarketingChecked, setIsMarketingChecked] = useState(false);
  const handleAcceptAll = () => {
    const gtmInitEvent = new CustomEvent('init-gtm');
    window.dispatchEvent(gtmInitEvent);
    Cookies.set('cookiesConsent', CookieConsentValues.ACCEPT_ALL, { expires: 90 });
    setIsModalOpened(false);
  };
  const handleNotAccept = () => {
    Cookies.set('cookiesConsent', CookieConsentValues.REJECT, { expires: 90 });
    setIsModalOpened(false);
  };
  const handleShowPreferences = () => {
    if (!isAdditionalChecksExpanded) {
      setIsAdditionalChecksExpanded(true);
      return;
    }
    Cookies.set(
      'cookiesConsent',
      isMarketingChecked ? CookieConsentValues.ACCEPT_ALL : CookieConsentValues.REJECT,
      { expires: 90 },
    );
    setIsModalOpened(false);
  };
  return (
    <>
      {isModalOpened && (
        <Wrapper>
          <Container>
            {t('cookieConsent.text.a')}&nbsp;
            <Link target="_blank" href={urls.cookies}>
              {t('cookieConsent.text.b')}
            </Link>
            &nbsp;
            {t('cookieConsent.text.c')}&nbsp;
            <br />
            {t('cookieConsent.text.d')}&nbsp;
            <br />
            {t('cookieConsent.text.e')}&nbsp;
            <Link target="_blank" href={urls.privacyPolicy}>
              {t('cookieConsent.text.f')}
            </Link>
            {t('cookieConsent.text.g')}
          </Container>
          <TransitionComponent
            inProp={isAdditionalChecksExpanded}
            isMarketingChecked={isMarketingChecked}
            setIsMarketingChecked={setIsMarketingChecked}
          />
          <div>
            <AcceptButton data-test-id="cookie.accept" onClick={handleAcceptAll}>
              {t('cookieConsent.buttons.acceptAll')}
            </AcceptButton>
            <FlexContainer>
              <Button onClick={handleNotAccept}>{t('cookieConsent.buttons.notAccept')}</Button>
              <Button onClick={handleShowPreferences}>
                {isAdditionalChecksExpanded
                  ? t('cookieConsent.buttons.save')
                  : t('cookieConsent.buttons.select')}
              </Button>
            </FlexContainer>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default EnhanceWithTheme(CookiePopup);
