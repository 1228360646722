import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'common/urls';
import { useDispatch, useSelector } from 'react-redux';
import { getAml } from 'store/selectors/auth';
import { AUDIT_PROOF_MODAL_TYPES, AuditProofModalType } from 'common/const/auditProof';
import { isAmlOverdue, isAmlOpen } from 'common/utils/auditProof';
import { getAfterLoginFlag } from 'store/selectors/localSettings';
import { setWasAuditProofModalClosed } from 'store/actions/localSettings';
import { ModalProps } from 'types/modals';
import AuditProofExitIntentModal from './AuditProofExitIntentModal';
import AuditProofLoginModal from './AuditProofLoginModal';

const AuditProofModals = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const aml = useSelector(getAml);
  const afterLoginFlag = useSelector(getAfterLoginFlag);

  const [isExitIntentModalOpen, setIsExitIntentModalOpen] = React.useState(false);
  const [isAfterLoginFlagTriggered, setIsAfterLoginFlagTriggered] = useState(false);
  const [modalType, setType] = useState<AuditProofModalType>(AUDIT_PROOF_MODAL_TYPES.QUESTION);

  useEffect(() => {
    if (afterLoginFlag && !isAfterLoginFlagTriggered) setIsAfterLoginFlagTriggered(true);
  }, [afterLoginFlag]);

  useEffect(() => {
    if (isAmlOverdue(aml.confirmationStatus)) setType(AUDIT_PROOF_MODAL_TYPES.BLOCKED);
  }, [aml.confirmationStatus]);

  const handleAcceptAudit = () => {
    dispatch(setWasAuditProofModalClosed());
    setIsExitIntentModalOpen(false);
    onClose();
    navigate(paths.AUDIT_PROOF);
  };

  const handleRejectAudit = () => {
    if (isAmlOverdue(aml.confirmationStatus)) {
      return;
    }

    if (isAmlOpen(aml.confirmationStatus)) {
      setIsExitIntentModalOpen(true);
    }
  };

  const handleRejectExitIntentModal = () => {
    dispatch(setWasAuditProofModalClosed());
    navigate(paths.PORTFOLIO);
    onClose();
  };

  return (
    <>
      <AuditProofLoginModal
        open={true}
        daysLeft={aml.countdown}
        handleRejectAudit={handleRejectAudit}
        handleAcceptAudit={handleAcceptAudit}
        isAmlOverdue={isAmlOverdue(aml.confirmationStatus)}
        type={modalType}
      />
      <AuditProofExitIntentModal
        open={isExitIntentModalOpen}
        daysLeft={aml.countdown}
        handleRejectExitIntentModal={handleRejectExitIntentModal}
        handleAcceptAudit={handleAcceptAudit}
      />
    </>
  );
};

export default AuditProofModals;
