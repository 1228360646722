import styled from 'styled-components';
import { Theme } from 'types/theme';

interface LabelProps {
  readonly $hasErrorOccurred: boolean;
  readonly $hasWarningOccurred: boolean;
  theme: Theme;
}

export const Wrapper = styled.div`
  position: relative;
`;

export const ErrorWrapper = styled.div`
  position: relative;

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ $hasErrorOccurred, theme }: LabelProps) =>
      $hasErrorOccurred ? theme.color.foreground.critical : theme.color.foreground.caution} !important;
  }
`;

export const Label = styled.label.attrs({
  className: 'standard-text',
})<LabelProps>`
  display: block;
  padding-bottom: 8px;
  color: ${({ $hasErrorOccurred, $hasWarningOccurred, theme }: LabelProps) => {
    if ($hasErrorOccurred) return theme.color.foreground.critical;

    if ($hasWarningOccurred) return theme.color.foreground.caution;

    return theme.color.foreground.primary;
  }};
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const CurrencyLabelBox = styled.div.attrs({ className: 'strong-weight' })`
  color: ${({ theme, $highlightError }: { theme: Theme; $highlightError: boolean }) =>
    $highlightError ? theme.color.foreground.critical : theme.color.foreground.focused};
  z-index: 2;
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.critical};
  padding-top: 10px;
`;

export const WarningContainer = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.caution};
  padding-top: 10px;
`;
