import styled from 'styled-components';
import { Theme } from 'types/theme';
import { colors } from 'common/utils/theme/colors';
import { devices } from 'common/styles';

export const Wrapper = styled.div`
  align-items: baseline;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.cookieBanner};
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  position: fixed;
  width: 600px;
  z-index: 9999;
  right: 1em;
  bottom: 1em;
  max-width: 100%;
  max-height: calc(100vh - 100px);
  padding: 1em 2em;
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 10px;

  @media${devices.maxTablet} {
    max-width: calc(100% - 2em);
  }
}`;

export const Container = styled.div.attrs({ className: 'standard-text primary-color-text' })`
  word-wrap: break-word;
  padding-top: 10px;
  padding-bottom: 16px;
  overflow-y: auto;
`;

export const FlexContainer = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button.attrs({ className: 'strong-weight' })`
  background-color: transparent;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  border: ${({ theme }: { theme: Theme }) => `${theme.border.width.large} solid ${theme.color.border.input}`};
  border-radius: 5px;
  padding: 8px;
  flex: 1;
  cursor: pointer;
  transition: all 0.2s;
  &:first-child {
    margin-right: 20px;
  }
  &:hover {
    border-color: #e6007e;
    color: #e6007e;
  }
`;

export const AcceptButton = styled(Button)`
  width: 100%;
  color: ${colors.pureWhite};
  background-color: #e6007e;
  border-color: #e6007e;
  &:hover {
    color: ${colors.pureWhite};
    border-color: ${({ theme }: { theme: Theme }) => theme.color.border.input};
    background-color: ${({ theme }: { theme: Theme }) => theme.color.border.input};
  }
`;

export const Link = styled.a.attrs({ className: 'strong-weight' })`
  color: #73cddd;
`;

export const ExpandedItemsContainer = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  margin: 16px 0;
  width: 100%;
`;

export const ExpandedItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
`;

export const ExpandedButton = styled(Button)<{ isExpanded: boolean }>`
  ${(props) => props.isExpanded && 'padding: 15px 8px;color: #336474; border-color: #336474;'}
`;

export const IconWrapper = styled.div<{ isChecked: boolean; isWithBorder: boolean; theme: Theme }>`
  height: 16px;
  width: 16px;
  ${({ isChecked, isWithBorder, theme }: { isChecked: boolean; isWithBorder: boolean; theme: Theme }) =>
    `border: ${theme.border.width.large} solid ${
      isChecked && !isWithBorder ? 'transparent' : theme.color.border.input
    }`}
  border-radius: ${({ theme }: { theme: Theme }) => theme.border.radius.standard};
  margin-right: 8px;
  position: relative;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Text = styled.div``;
