import React from 'react';
import { useTranslation } from 'react-i18next';
import { KycStatus } from 'types/auth';
import { devices } from 'common/styles';
import { useMedia } from 'common/hooks/useMedia';
import { kycStatuses } from 'common/const';
import { colors } from 'common/utils/theme/colors';
import { Container, Content, Text, Switch } from './styles';

export type Props = {
  switchingRealMoneyMode: boolean;
  handleAccountToggle: (targetLabel: string) => void;
  mobileMenuOpen: boolean;
  kycStatus: KycStatus;
};

const PaperTradingBanner = ({
  switchingRealMoneyMode,
  handleAccountToggle,
  mobileMenuOpen,
  kycStatus,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMedia([devices.maxTablet, devices.tablet], [true, false], false);
  const textKey = isMobile ? 'banners.mobilePaperMoneyText' : 'banners.paperMoneyText';
  const isBannerHidden = isMobile && mobileMenuOpen;
  const kycedText = isMobile ? 'banners.switchMobileKycedAcc' : 'banners.switchKycedAcc';
  const switchText = kycStatus === kycStatuses.Confirmed ? kycedText : 'banners.switch';

  return (
    <Container isBannerHidden={isBannerHidden}>
      <Content>
        <Text>{t(textKey)}</Text>
        <Switch
          onText={t(switchText)}
          offText={t(switchText)}
          value={false}
          onClick={handleAccountToggle}
          inProgress={switchingRealMoneyMode}
          styles={{
            color: colors.white,
          }}
        />
      </Content>
    </Container>
  );
};

export default PaperTradingBanner;
