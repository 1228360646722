import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { Theme } from 'types/theme';
import { devices, dimensions } from 'common/styles';

export const NotificationWrapper = styled.div``;

export const StyledToast = styled(ToastContainer).attrs({
  className: 'small-text medium-weight',
})`
  @media${devices.maxTablet} {
    width: calc(100% - 40px);
    padding: 4px;
  }

  /* 
  Width is 1/3 of content for desktops 
  Related container is common/LayoutContainer/LayoutRegularWrapper
  */
  @media${devices.tablet} {
    bottom: 38px;
    width: calc((100% - ${dimensions.standardPaddings * 2}px) / 3);
    max-width: ${dimensions.maxContentWidth / 3}px;
  }

  @media${devices.maxTablet} {
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
  }

  .Toastify__toast {
    border-radius: 4px;
    gap: 10px;
    min-height: 32px;

    @media${devices.maxMobileXL} {
      margin-bottom: 12px;
    }
  }

  ${({ theme }: { theme: Theme }) => `
    .Toastify__toast-body {
    margin: 0;
    padding: 5px 0 4px 8px;
    font-family: ${theme.typography.fontFamily.openSans}
  }
  
    .Toastify__toast--success {
    background: ${theme.color.background.notifications.success.mainColor};
    box-shadow: 0px 4.300000190734863px 8.600000381469727px 0px ${theme.color.background.notifications.success.boxShadow};
  }
  .Toastify__toast--info {
    background: ${theme.color.background.notifications.info.mainColor};
    box-shadow: 0px 4.300000190734863px 8.600000381469727px 0px ${theme.color.background.notifications.info.boxShadow};
  }
  .Toastify__toast--warning {
    background: ${theme.color.background.notifications.warning.mainColor};
    box-shadow: 0px 4.300000190734863px 8.600000381469727px 0px ${theme.color.background.notifications.warning.boxShadow};
  }
  .Toastify__toast--error {
    background: ${theme.color.background.notifications.error.mainColor};
    box-shadow: 0px 4.300000190734863px 8.600000381469727px 0px ${theme.color.background.notifications.error.boxShadow};
  }
  
  `}
`;

export const CloseButton = styled.button.attrs({ className: 'Toastify__close-button' })`
  padding: 4px;
  width: 32px;
  height: 32px;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.secondary}4A;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
`;
