// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from 'types/modals';
import {
  Banner,
  BannerButton,
  BannerContainer,
  BannerClose,
  BisonIcon,
  Heading1,
  Heading2,
  TextContainer,
} from './styles';

type Props = {
  close: () => void;
  showInstallPrompt: () => void;
} & ModalProps;

const AndroidBanner = ({ close, showInstallPrompt, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Banner>
      <BannerContainer>
        <BannerClose
          onClick={() => {
            close();

            onClose();
          }}
        />
        <BisonIcon />
        <TextContainer>
          <Heading1>BISON</Heading1>
          <Heading2>{t('androidBanner.text')}</Heading2>
        </TextContainer>
        <BannerButton onClick={showInstallPrompt} title={t('androidBanner.view')} />
      </BannerContainer>
    </Banner>
  );
};

export default AndroidBanner;
