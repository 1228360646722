import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import { CheckboxInputWithoutFormik } from 'common/components/CheckboxInput';
import { getTopUpsTermsAndConditions, TNC_POPUP_TYPES } from 'common/const/termsAndConditions';
import Button from 'common/components/Button';
import TncExitIntent from 'common/components/Modal/TermsAndConditionsModal/ExitIntent';
import { MODAL_DIMENSIONS } from 'common/const/modals';
import { useFeatureSpecificTncConsentModal } from 'common/hooks/useFeatureSpecificTncConsentModal';
import { ModalProps } from 'types/modals';
import {
  Body,
  BodyText,
  ButtonWrapper,
  CheckboxText,
  DocumentAttachment,
  DocumentsWrapper,
  Title,
} from './styles';

const FeatureSpecificTncConsentModal = ({ onClose }: ModalProps) => {
  const { t } = useTranslation();

  const { activePopUp, setActivePopUp, error, checkBoxState, toggleCheckbox, declineTerms, agreeTerms } =
    useFeatureSpecificTncConsentModal({ onClose });

  return (
    <>
      <TncExitIntent
        dimensions={MODAL_DIMENSIONS.SMALL}
        isOpened={activePopUp === TNC_POPUP_TYPES.EXIT_INTENT}
        onClose={declineTerms}
        onBackToPopUp={() => setActivePopUp(TNC_POPUP_TYPES.TNC)}
        customText={{
          title: t('featureTermsAndConditions.exitPopup.title'),
          text: t('featureTermsAndConditions.exitPopup.body'),
          primaryButton: t('featureTermsAndConditions.exitPopup.primaryAction'),
          secondaryButton: t('featureTermsAndConditions.exitPopup.secondaryAction'),
        }}
      />
      <ModalTemplate
        defaultOpen={activePopUp === TNC_POPUP_TYPES.TNC}
        onCancel={() => setActivePopUp(TNC_POPUP_TYPES.EXIT_INTENT)}
        header={<Title>{t('featureTermsAndConditions.title')}</Title>}
        actions={
          <ButtonWrapper>
            <Button title={t('featureTermsAndConditions.agree')} onClick={agreeTerms} size="modal" />
          </ButtonWrapper>
        }
      >
        <Body>
          <BodyText>{t('featureTermsAndConditions.bodyText')}</BodyText>
          <CheckboxInputWithoutFormik
            disableMargins
            onClick={toggleCheckbox}
            value={checkBoxState}
            error={error}
          >
            <CheckboxText error={error}>{t('featureTermsAndConditions.checkboxText')}</CheckboxText>
          </CheckboxInputWithoutFormik>
          <DocumentsWrapper>
            {getTopUpsTermsAndConditions().map((document) => (
              <DocumentAttachment
                key={document.title}
                fileName={document.title}
                onClick={() => window.open(document.link, '_blank')}
              />
            ))}
          </DocumentsWrapper>
        </Body>
      </ModalTemplate>
    </>
  );
};

export default FeatureSpecificTncConsentModal;
